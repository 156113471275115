$path: '../fonts/';

@font-face {
  font-family: 'Helvetica';
  src: url('#{$path}Helvetica.eot');
  src: url('#{$path}Helvetica.eot?#iefix') format('embedded-opentype'),
      url('#{$path}Helvetica.woff2') format('woff2'),
      url('#{$path}Helvetica.woff') format('woff'),
      url('#{$path}Helvetica.ttf') format('truetype'),
      url('#{$path}Helvetica.svg#Helvetica') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica-Bold';
  src: url('#{$path}Helvetica-Bold.eot');
  src: url('#{$path}Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$path}Helvetica-Bold.woff2') format('woff2'),
      url('#{$path}Helvetica-Bold.woff') format('woff'),
      url('#{$path}Helvetica-Bold.ttf') format('truetype'),
      url('#{$path}Helvetica-Bold.svg#Helvetica-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MyriadPro';
  src: url('#{$path}MyriadPro-Regular.eot');
  src: url('#{$path}MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$path}MyriadPro-Regular.woff2') format('woff2'),
      url('#{$path}MyriadPro-Regular.woff') format('woff'),
      url('#{$path}MyriadPro-Regular.ttf') format('truetype'),
      url('#{$path}MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MyriadPro-Light';
  src: url('#{$path}MyriadPro-Light.eot');
  src: url('#{$path}MyriadPro-Light.eot?#iefix') format('embedded-opentype'),
      url('#{$path}MyriadPro-Light.woff2') format('woff2'),
      url('#{$path}MyriadPro-Light.woff') format('woff'),
      url('#{$path}MyriadPro-Light.ttf') format('truetype'),
      url('#{$path}MyriadPro-Light.svg#MyriadPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MyriadPro-Bold';
  src: url('#{$path}MyriadPro-Bold.eot');
  src: url('#{$path}MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$path}MyriadPro-Bold.woff2') format('woff2'),
      url('#{$path}MyriadPro-Bold.woff') format('woff'),
      url('#{$path}MyriadPro-Bold.ttf') format('truetype'),
      url('#{$path}MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('#{$path}Poppins-Light.eot');
  src: url('#{$path}Poppins-Light.eot?#iefix') format('embedded-opentype'),
      url('#{$path}Poppins-Light.woff2') format('woff2'),
      url('#{$path}Poppins-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('#{$path}Poppins-Regular.eot');
  src: url('#{$path}Poppins-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$path}Poppins-Regular.woff2') format('woff2'),
      url('#{$path}Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('#{$path}Poppins-Medium.eot');
  src: url('#{$path}Poppins-Medium.eot?#iefix') format('embedded-opentype'),
      url('#{$path}Poppins-Medium.woff2') format('woff2'),
      url('#{$path}Poppins-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('#{$path}Poppins-Bold.eot');
  src: url('#{$path}Poppins-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$path}Poppins-Bold.woff2') format('woff2'),
      url('#{$path}Poppins-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gogh-Bold';
  src: url('#{$path}Gogh-Bold.eot');
  src: url('#{$path}Gogh-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$path}Gogh-Bold.woff2') format('woff2'),
      url('#{$path}Gogh-Bold.woff') format('woff'),
      url('#{$path}Gogh-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gogh-BoldItalic';
  src: url('#{$path}Gogh-BoldItalic.eot');
  src: url('#{$path}Gogh-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('#{$path}Gogh-BoldItalic.woff2') format('woff2'),
      url('#{$path}Gogh-BoldItalic.woff') format('woff'),
      url('#{$path}Gogh-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gogh-Regular';
  src: url('#{$path}Gogh-Regular.eot');
  src: url('#{$path}Gogh-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$path}Gogh-Regular.woff2') format('woff2'),
      url('#{$path}Gogh-Regular.woff') format('woff'),
      url('#{$path}Gogh-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}