.slick {
  &-arrow {
    transition: all .5s;
    background: rgba(29, 40, 58, .5);
    height: 40px;
    width: 40px;
    border-radius: 2px;

    @include breakpoint(sm) {
      height: 30px;
      width: 30px;
    }
  }

  &-prev,
  &-next {
    z-index: 2;

    &::before {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      font-family: "Font Awesome 6 Free";
      font-size: 1rem;
      -webkit-font-smoothing: antialiased;

      @include breakpoint(sm) {
        font-size: .8rem;
      }
    }

    &:hover,
    &:focus {
      background: rgba(29, 40, 58, .7);
    }
  }

  &-prev {
    left: 25px;

    @include breakpoint(sm) {
      left: 15px;
    }

    &::before {
      content: "\f053";
    }
  }

  &-next {
    right: 25px;

    @include breakpoint(sm) {
      right: 15px;
    }

    &::before {
      content: "\f054";
    }
  }
}

.slick-mobile {
  margin-left: 0;
  margin-right: 0;

  .slick-slide {
    padding: 0 .4rem;
  }
}
