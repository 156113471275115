@import url('http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,600,700,300,800');
@import './components/_fonts.scss';
@import './components/_media-queries.scss';
@import './components/_library.scss';

$wikaOrange: #ef8122;
$wikaDarkOrange: #f57e20;
$wikaBlack: #001429;
$wikaDarkBlue: #171a3f;
$wikaGrey: #9b9b9b;

html {
  scroll-behavior: smooth;
}

body {
  background: #fff;
  font-family: 'Helvetica';
  color: #4e4e4e;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Helvetica';
  color: $wikaDarkBlue;

  &.bold,
  .bold {
    font-family: 'Helvetica-Bold';
  }
}

p {
  font-family: 'Poppins-Regular';
  color: $wikaDarkBlue;
}

a {
  font-family: 'MyriadPro';
}

.section {
  &-container {
    scroll-margin-top: 100px;
    margin-top: 5rem;
  }

  &-title {
    font-family: 'Helvetica';
    font-size: 40px;
    margin-bottom: 2rem;

    &.bold,
    .bold {
      font-family: 'Helvetica-Bold';
    }
  }
}

/* Header Menu Section */
#Header {
  &::after {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    right: 80px;
    top: 20px;
    bottom: 0;
    z-index: 2;
    width: 40px;
    height: 40px;
    margin-left: auto;
    border-radius: 40px;
    background-color: rgba(221, 221, 221, .9);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    opacity: 0;
    visibility: hidden;
    transform-origin: top 7.8vw;
    z-index: 999;
  }

  &.show {
    &::after {
      border-radius: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      -webkit-transition: .2s ease-in-out;
      -moz-transition: .2s ease-in-out;
      -o-transition: .2s ease-in-out;
      transition: .2s ease-in-out;
      visibility: visible;
      opacity: 1;
    }

    .header-menu {
      box-shadow: none;
      background-color: transparent;
      overflow-y: auto;
      height: 100vh;

      &.on-scroll {
        background-color: transparent;
        box-shadow: none;
      }

      .menu-logo {
        .logo-images {
          background-image: url('../images/wika-logo.png');
        }
      }

      .menu-right {
        .separator {
          background-color: $wikaBlack;
        }
      }

      .language {
        opacity: 1;

        &-btn {
          color: $wikaBlack;
        }
      }
    }
  }

  .header-menu {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    padding: .6rem 2rem;
    transition: .2s ease-in-out;

    &::-webkit-scrollbar {
      display: none;
    }

    @include breakpoint(sm) {
      padding: 0;
    }

    &.on-scroll {
      background-color: rgba(255, 255, 255, 1);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      transition: .3s ease-in-out;

      .burger-menu {
        span {
          background: $wikaDarkBlue;
        }
      }

      .menu-logo {
        .logo-images {
          background-image: url('../images/wika-logo.png');
        }
      }

      .language {
        .separator {
          background-color: $wikaBlack;
        }

        &-btn {
          color: $wikaBlack;
        }
      }
    }

    .menu-container {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      @include breakpoint(sm) {
        padding: 0.7rem 1rem;
      }
    }

    .menu-logo {
      display: inline-block;
      max-width: 240px;
      width: 100%;

      @include breakpoint(sm) {
        max-width: 140px;
      }

      .logo-images {
        display: block;
        background-image: url('../images/wika-logo-white.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 64px;

        @include breakpoint(sm) {
          height: 36px;
        }
      }
    }

    .menu-right {
      display: flex;
      align-items: center;

      .separator {
        display: inline-block;
        background-color: #fff;
        width: 2px;
        height: 22px;
        margin: 0 .8rem;
      }
    }


    .language {
      display: flex;
      align-items: center;
      margin-right: 2rem;

      &-btn {
        position: relative;
        display: inline-block;
        font-family: 'Poppins-Regular';
        color: #fff;
        text-decoration: none;
        font-size: 20px;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          font-family: 'Poppins-Medium';

          .language {
            &-logo {
              opacity: 0;
            }

            &-text {
              opacity: 1;
            }
          }
        }
      }

      &-text {
        text-align: center;
      }
    }

    .burger-menu {
      width: 40px;
      height: 30px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;

      @include breakpoint(sm) {
        width: 30px;
        height: 22px;
      }

      span {
        display: block;
        position: absolute;
        height: 6px;
        width: 100%;
        background-color: #fff;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        @include breakpoint(sm) {
          height: 4px;
        }

        &:nth-child(1) {
          top: 0px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          top: 12px;

          @include breakpoint(sm) {
            top: 9px;
          }
        }

        &:nth-child(4) {
          top: 24px;

          @include breakpoint(sm) {
            top: 18px;
          }
        }
      }

      &.open {
        span {
          background: $wikaDarkBlue;

          &:nth-child(1) {
            top: 18px;
            width: 0%;
            left: 50%;
          }

          &:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          &:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }

          &:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
          }
        }
      }
    }
  }
}

.menu-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  visibility: hidden;
  opacity: 0;
  transition: .25s ease-in-out;
  height: 100%;
  max-height: 0;

  @include breakpoint(sm) {
    display: block;
    overflow-y: hidden;
    transition: max-height .6s, padding .6s;
    max-height: 0;
    padding: 0 .5rem;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    max-height: 100vh;
    padding: 2rem 1rem;

    @include breakpoint(sm) {
      max-height: 80vh;
      overflow-y: auto;
      padding: 1rem;
    }
  }

  a {
    font-family: 'Poppins-Regular';
    text-transform: capitalize;
    font-size: 1.4rem;
  }

  .menu-level {
    &-1 {
      text-align: right;
      padding: 0 1rem;
      margin-bottom: 1.6rem;

      @include breakpoint(sm) {
        padding: 0.4rem 0.6rem;
        margin-bottom: 1rem;
      }

      a {
        display: block;
        text-decoration: none;
        color: $wikaBlack;

        span {
          display: inline-block;
        }

        &.active,
        &:hover {
          color: $wikaOrange;
          transition: ease 0.3s;
        }
      }

      .menu-icon {
        margin-left: 6px;
        display: none;

        &.show {
          display: inline-block;
        }
      }
    }

    &-2 {
      display: none;
      text-align: right;
      min-width: 10rem;
      padding: 0;
      margin: 0;
      list-style: none;
      border-radius: 0.375rem;
      margin-top: 0.5rem;

      li {
        padding: .5rem 0;
        margin-right: 2rem;

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            display: block;
            flex: 1;
          }

          .menu-icon {
            padding-left: 1rem;
          }
        }
      }
    }
  }
}

/* #Banner Section */
#Banner {
  position: relative;

  .animate {
    &__fadeIn {
      --animate-duration: 2s;
    }
  }

  .animate__animated.animate__bounce {
    --animate-duration: 2s;
  }

  .btn-scroll-down {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    bottom: 2rem;
    left: 50%;
    margin: auto;
    transform: translateX(-50%);
    animation-name: move;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;

    img {
      width: 20px;
      height: auto;
    }

    i {
      color: #fff;
      font-size: 1.2rem;
      opacity: 0;
      animation-name: visible;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;

      &:last-child {
        margin-top: -10px;
        animation-duration: 1.5s;
      }
    }

    @keyframes move {
      0% {
        bottom: 2rem;
      }

      50% {
        bottom: 1rem;
      }

      100% {
        bottom: 2rem;
      }
    }

    @keyframes visible {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }
}

.banner {
  &-container {
    .slick-arrow {
      opacity: 0;

      @include breakpoint(sm) {
        opacity: 1;
      }
    }

    &:hover {
      .slick-arrow {
        opacity: 1;
      }

    }
  }

  &-items {
    position: relative;
    height: 100vh;

    @include breakpoint(sm) {
      height: 70vh;
    }

    &__images {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 100%;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.25);
      }
    }

    &__text-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin-top: 15%;
      padding-left: 5rem;
      padding-right: 5rem;
      max-width: 58rem;

      @include breakpoint(sm) {
        padding-left: 1rem;
        padding-right: 1rem;
        max-width: 100%;
        align-items: flex-end;
        margin-top: 50%;
        margin-bottom: 2rem;
      }
    }

    &__title {
      font-family: 'Gogh-BoldItalic';
      display: block;
      color: #fff;
      font-size: 4rem;
      line-height: 4rem;
      margin-bottom: .5rem;
      text-transform: uppercase;

      @include breakpoint(sm) {
        font-size: 2rem;
        line-height: 2rem;
        margin-bottom: 2rem;
      }
    }

    &__desc {
      font-family: 'Poppins-Regular';
      color: #fff;
      font-size: 1.6rem;

      @include breakpoint(sm) {
        font-size: 1.2rem;
      }
    }

    &__project-name {
      color: #fff;
      position: absolute;
      bottom: 4rem;
      right: 8rem;
      font-family: 'Poppins-Regular';

      @include breakpoint(sm) {
        right: 1rem;
        left: 1rem;
        bottom: 2rem;
        font-size: 14px;
      }
    }
  }
}

/* #About Section */
.about {
  &-desc {
    font-size: 18px;
    text-align: justify;
    font-weight: 600;
    font-family: 'Poppins-Regular';

    .highlight {
      color: $wikaDarkOrange;
      font-family: 'Poppins-Medium';
      font-style: italic;
      font-weight: bold;
    }
  }

  &-image-container {
    margin-right: 2rem;
  }

  &-image {
    width: 479px;
    height: auto;

    @include breakpoint(sm) {
      width: 200px;
      margin: auto;
      margin-bottom: 1rem;
    }
  }
}

/* #OurProduct Section */
#Product {
  margin-top: 10rem;
  margin-bottom: 10rem;

  .product-images {
    transition: ease-in .2s;

    @include breakpoint(sm) {
      filter: none;
    }
  }

  .product-main {
    width: 92%;
    margin: auto;

    @include breakpoint(sm) {
      width: 200px;
      margin-bottom: 1rem;
    }
  }
}

#Project {
  * {
    min-height: 0;
    min-width: 0;
  }

  .project-slider__container {
    position: relative;
    height: 90vh;
    display: flex;
    align-items: flex-end;
    padding-bottom: 4rem;

    @include breakpoint(sm) {
      height: 500px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.4;
      background-image: url("../images/bg-our-project.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

  }

  .project-images-container {
    border-radius: 68px;

    &.lt1 {
      .images-container-overlay {
        transform: translate(10%, 0) scale(.75);
      }
    }

    &.rt1 {
      .images-container-overlay {
        transform: translate(-10%, 0) scale(.75);
      }
    }
  }

  .images-container-overlay {
    transition: all 0.4s ease;
    border-radius: 21%;
    margin: 0 8px;

    // opacity: 0.7;
    // transform: scale(0.7);
    @include breakpoint(sm) {
      border-radius: 20px;
    }

    .overlay-text {
      font-size: 1.6rem;
    }
  }

  .slider-project {
    width: 100%;
  }

  .slick-arrow {
    background: rgba(29, 40, 58, 1);
  }

  .slick-center {
    .images-container-overlay {
      transition: all 0.4s ease;
      // transform: scale(1);
      // opacity: 1;
    }
  }
}

/* #MediaHighlight Section */
#Media {
  .section-title {
    margin-bottom: 4rem;
  }

  .media {
    &-container {
      margin: 0 auto 2rem;
    }

    &-content {
      &__container {
        padding: 2rem;
        border-radius: 2rem;
        background-color: #f4f4f4;
        margin: 0 .5rem;

        @include breakpoint(sm) {
          display: block;
        }
      }

      &__text {
        height: 300px;
        overflow: hidden;
      }

      &__title {
        font-family: 'Poppins-Medium';
        font-size: 25px;
        text-align: center;
        margin-top: 2rem;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;

        @include breakpoint(sm) {
          margin-top: 1rem;
        }
      }

      &__desc {
        font-family: 'Poppins-Regular';
        margin-top: .8rem;
        text-align: justify;
        font-size: 15px;

        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
    }

    &-images {
      width: 100%;
      height: 300px;
      background-position: center;
      /* Center the image */
      background-repeat: no-repeat;
      /* Do not repeat the image */
      background-size: cover;
      /* Resize the background image to cover the entire container */

      &-container {
        border-radius: 26px;

        @include breakpoint(sm) {
          margin-right: 0;
        }
      }
    }
  }

  .slick-next {
    right: -45px;

    @include breakpoint(sm) {
      right: 0px;
    }
  }

  .slick-prev {
    left: -45px;

    @include breakpoint(sm) {
      left: 0px;
    }
  }
}

.images-container-overlay {
  position: relative;
  overflow: hidden;
  border-radius: 42px;
  display: flex;
  justify-content: center;

  &:hover {
    .overlay-text {
      opacity: 1;
    }
  }

  .overlay-text {
    display: flex;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    font-family: 'Poppins-Regular';
    font-size: 1.4rem;
    padding: .5rem;
    color: #fff;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all .4s ease;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

/* #Footer Section */
#FooterMenu {
  background-color: $wikaGrey;
  padding: 2rem 0;

  ul {
    list-style-type: none;

    li {
      padding: 4px 0;
      border-bottom: 1px dotted #4d4d4d;
    }
  }

  a {
    text-decoration: none;
  }

  a,
  p {
    font-family: 'Poppins-Regular';
    color: #fff;
  }

  .widget {
    ul {
      padding: 20px 0 40px 0;
    }

    p {
      margin-top: 1rem;
      text-align: justify;
    }
  }

  .footer-menu-title {
    text-transform: uppercase;
    font-weight: 600;
  }
}

.social-media {
  &-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -3rem;

    @include breakpoint(sm) {
      margin-top: 1rem;
      justify-content: flex-start;
    }

    .separator {
      width: 4px;
      height: 30px;
      background-color: #fff;
      margin: 0 1rem;

      @include breakpoint(sm) {
        margin: 0 .8rem;
      }
    }
  }

  &-link {
    display: inline-block;
    font-size: 2rem;
    margin-right: 12px;

    @include breakpoint(sm) {
      font-size: 1.6rem;
      margin-right: 4px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &-account a {
    font-size: 22px;
    font-family: 'Poppins-Regular';
    font-weight: 600;
    letter-spacing: 1px;

    @include breakpoint(sm) {
      font-size: 14px;
    }
  }
}

.copyright {
  background-color: #f8991d;
  padding: 30px 0;

  p {
    margin-bottom: 0;
    color: #fff;
  }
}

/* Scroll to Top */
.scroll-top {
  opacity: 0;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  cursor: pointer;
  background-color: $wikaDarkOrange;
  padding: .6rem;
  display: flex;
  border-radius: 2px;
  transition: all .5s ease;

  &.show {
    opacity: 1;
  }
}