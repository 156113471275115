@import url("http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,600,700,300,800");
@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/Helvetica.eot");
  src: url("../fonts/Helvetica.eot?#iefix") format("embedded-opentype"), url("../fonts/Helvetica.woff2") format("woff2"), url("../fonts/Helvetica.woff") format("woff"), url("../fonts/Helvetica.ttf") format("truetype"), url("../fonts/Helvetica.svg#Helvetica") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Helvetica-Bold';
  src: url("../fonts/Helvetica-Bold.eot");
  src: url("../fonts/Helvetica-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Helvetica-Bold.woff2") format("woff2"), url("../fonts/Helvetica-Bold.woff") format("woff"), url("../fonts/Helvetica-Bold.ttf") format("truetype"), url("../fonts/Helvetica-Bold.svg#Helvetica-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'MyriadPro';
  src: url("../fonts/MyriadPro-Regular.eot");
  src: url("../fonts/MyriadPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/MyriadPro-Regular.woff2") format("woff2"), url("../fonts/MyriadPro-Regular.woff") format("woff"), url("../fonts/MyriadPro-Regular.ttf") format("truetype"), url("../fonts/MyriadPro-Regular.svg#MyriadPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'MyriadPro-Light';
  src: url("../fonts/MyriadPro-Light.eot");
  src: url("../fonts/MyriadPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/MyriadPro-Light.woff2") format("woff2"), url("../fonts/MyriadPro-Light.woff") format("woff"), url("../fonts/MyriadPro-Light.ttf") format("truetype"), url("../fonts/MyriadPro-Light.svg#MyriadPro-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'MyriadPro-Bold';
  src: url("../fonts/MyriadPro-Bold.eot");
  src: url("../fonts/MyriadPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/MyriadPro-Bold.woff2") format("woff2"), url("../fonts/MyriadPro-Bold.woff") format("woff"), url("../fonts/MyriadPro-Bold.ttf") format("truetype"), url("../fonts/MyriadPro-Bold.svg#MyriadPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins-Light';
  src: url("../fonts/Poppins-Light.eot");
  src: url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Light.woff2") format("woff2"), url("../fonts/Poppins-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins-Regular';
  src: url("../fonts/Poppins-Regular.eot");
  src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Regular.woff2") format("woff2"), url("../fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins-Medium';
  src: url("../fonts/Poppins-Medium.eot");
  src: url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Medium.woff2") format("woff2"), url("../fonts/Poppins-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins-Bold';
  src: url("../fonts/Poppins-Bold.eot");
  src: url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Bold.woff2") format("woff2"), url("../fonts/Poppins-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gogh-Bold';
  src: url("../fonts/Gogh-Bold.eot");
  src: url("../fonts/Gogh-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Gogh-Bold.woff2") format("woff2"), url("../fonts/Gogh-Bold.woff") format("woff"), url("../fonts/Gogh-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gogh-BoldItalic';
  src: url("../fonts/Gogh-BoldItalic.eot");
  src: url("../fonts/Gogh-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Gogh-BoldItalic.woff2") format("woff2"), url("../fonts/Gogh-BoldItalic.woff") format("woff"), url("../fonts/Gogh-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gogh-Regular';
  src: url("../fonts/Gogh-Regular.eot");
  src: url("../fonts/Gogh-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Gogh-Regular.woff2") format("woff2"), url("../fonts/Gogh-Regular.woff") format("woff"), url("../fonts/Gogh-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

.slick-arrow {
  transition: all .5s;
  background: rgba(29, 40, 58, 0.5);
  height: 40px;
  width: 40px;
  border-radius: 2px; }
  @media (max-width: 767px) {
    .slick-arrow {
      height: 30px;
      width: 30px; } }

.slick-prev, .slick-next {
  z-index: 2; }
  .slick-prev::before, .slick-next::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    font-family: "Font Awesome 6 Free";
    font-size: 1rem;
    -webkit-font-smoothing: antialiased; }
    @media (max-width: 767px) {
      .slick-prev::before, .slick-next::before {
        font-size: .8rem; } }
  .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    background: rgba(29, 40, 58, 0.7); }

.slick-prev {
  left: 25px; }
  @media (max-width: 767px) {
    .slick-prev {
      left: 15px; } }
  .slick-prev::before {
    content: "\f053"; }

.slick-next {
  right: 25px; }
  @media (max-width: 767px) {
    .slick-next {
      right: 15px; } }
  .slick-next::before {
    content: "\f054"; }

.slick-mobile {
  margin-left: 0;
  margin-right: 0; }
  .slick-mobile .slick-slide {
    padding: 0 .4rem; }

html {
  scroll-behavior: smooth; }

body {
  background: #fff;
  font-family: 'Helvetica';
  color: #4e4e4e;
  margin: 0; }

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Helvetica';
  color: #171a3f; }
  h1.bold,
  h1 .bold,
  h2.bold,
  h2 .bold,
  h3.bold,
  h3 .bold,
  h4.bold,
  h4 .bold,
  h5.bold,
  h5 .bold {
    font-family: 'Helvetica-Bold'; }

p {
  font-family: 'Poppins-Regular';
  color: #171a3f; }

a {
  font-family: 'MyriadPro'; }

.section-container {
  scroll-margin-top: 100px;
  margin-top: 5rem; }

.section-title {
  font-family: 'Helvetica';
  font-size: 40px;
  margin-bottom: 2rem; }
  .section-title.bold,
  .section-title .bold {
    font-family: 'Helvetica-Bold'; }

/* Header Menu Section */
#Header::after {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  right: 80px;
  top: 20px;
  bottom: 0;
  z-index: 2;
  width: 40px;
  height: 40px;
  margin-left: auto;
  border-radius: 40px;
  background-color: rgba(221, 221, 221, 0.9);
  transition: .25s ease-in-out;
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: top 7.8vw;
          transform-origin: top 7.8vw;
  z-index: 999; }

#Header.show::after {
  border-radius: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: .2s ease-in-out;
  visibility: visible;
  opacity: 1; }

#Header.show .header-menu {
  box-shadow: none;
  background-color: transparent;
  overflow-y: auto;
  height: 100vh; }
  #Header.show .header-menu.on-scroll {
    background-color: transparent;
    box-shadow: none; }
  #Header.show .header-menu .menu-logo .logo-images {
    background-image: url("../images/wika-logo.png"); }
  #Header.show .header-menu .menu-right .separator {
    background-color: #001429; }
  #Header.show .header-menu .language {
    opacity: 1; }
    #Header.show .header-menu .language-btn {
      color: #001429; }

#Header .header-menu {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  padding: .6rem 2rem;
  transition: .2s ease-in-out; }
  #Header .header-menu::-webkit-scrollbar {
    display: none; }
  @media (max-width: 767px) {
    #Header .header-menu {
      padding: 0; } }
  #Header .header-menu.on-scroll {
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: .3s ease-in-out; }
    #Header .header-menu.on-scroll .burger-menu span {
      background: #171a3f; }
    #Header .header-menu.on-scroll .menu-logo .logo-images {
      background-image: url("../images/wika-logo.png"); }
    #Header .header-menu.on-scroll .language .separator {
      background-color: #001429; }
    #Header .header-menu.on-scroll .language-btn {
      color: #001429; }
  #Header .header-menu .menu-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (max-width: 767px) {
      #Header .header-menu .menu-container {
        padding: 0.7rem 1rem; } }
  #Header .header-menu .menu-logo {
    display: inline-block;
    max-width: 240px;
    width: 100%; }
    @media (max-width: 767px) {
      #Header .header-menu .menu-logo {
        max-width: 140px; } }
    #Header .header-menu .menu-logo .logo-images {
      display: block;
      background-image: url("../images/wika-logo-white.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 64px; }
      @media (max-width: 767px) {
        #Header .header-menu .menu-logo .logo-images {
          height: 36px; } }
  #Header .header-menu .menu-right {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    #Header .header-menu .menu-right .separator {
      display: inline-block;
      background-color: #fff;
      width: 2px;
      height: 22px;
      margin: 0 .8rem; }
  #Header .header-menu .language {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 2rem; }
    #Header .header-menu .language-btn {
      position: relative;
      display: inline-block;
      font-family: 'Poppins-Regular';
      color: #fff;
      text-decoration: none;
      font-size: 20px; }
      #Header .header-menu .language-btn:last-child {
        margin-right: 0; }
      #Header .header-menu .language-btn:hover {
        font-family: 'Poppins-Medium'; }
        #Header .header-menu .language-btn:hover .language-logo {
          opacity: 0; }
        #Header .header-menu .language-btn:hover .language-text {
          opacity: 1; }
    #Header .header-menu .language-text {
      text-align: center; }
  #Header .header-menu .burger-menu {
    width: 40px;
    height: 30px;
    position: relative;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer; }
    @media (max-width: 767px) {
      #Header .header-menu .burger-menu {
        width: 30px;
        height: 22px; } }
    #Header .header-menu .burger-menu span {
      display: block;
      position: absolute;
      height: 6px;
      width: 100%;
      background-color: #fff;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: .25s ease-in-out; }
      @media (max-width: 767px) {
        #Header .header-menu .burger-menu span {
          height: 4px; } }
      #Header .header-menu .burger-menu span:nth-child(1) {
        top: 0px; }
      #Header .header-menu .burger-menu span:nth-child(2), #Header .header-menu .burger-menu span:nth-child(3) {
        top: 12px; }
        @media (max-width: 767px) {
          #Header .header-menu .burger-menu span:nth-child(2), #Header .header-menu .burger-menu span:nth-child(3) {
            top: 9px; } }
      #Header .header-menu .burger-menu span:nth-child(4) {
        top: 24px; }
        @media (max-width: 767px) {
          #Header .header-menu .burger-menu span:nth-child(4) {
            top: 18px; } }
    #Header .header-menu .burger-menu.open span {
      background: #171a3f; }
      #Header .header-menu .burger-menu.open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%; }
      #Header .header-menu .burger-menu.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      #Header .header-menu .burger-menu.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
      #Header .header-menu .burger-menu.open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%; }

.menu-list-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  visibility: hidden;
  opacity: 0;
  transition: .25s ease-in-out;
  height: 100%;
  max-height: 0; }
  @media (max-width: 767px) {
    .menu-list-container {
      display: block;
      overflow-y: hidden;
      transition: max-height .6s, padding .6s;
      max-height: 0;
      padding: 0 .5rem; } }
  .menu-list-container.show {
    opacity: 1;
    visibility: visible;
    max-height: 100vh;
    padding: 2rem 1rem; }
    @media (max-width: 767px) {
      .menu-list-container.show {
        max-height: 80vh;
        overflow-y: auto;
        padding: 1rem; } }
  .menu-list-container a {
    font-family: 'Poppins-Regular';
    text-transform: capitalize;
    font-size: 1.4rem; }
  .menu-list-container .menu-level-1 {
    text-align: right;
    padding: 0 1rem;
    margin-bottom: 1.6rem; }
    @media (max-width: 767px) {
      .menu-list-container .menu-level-1 {
        padding: 0.4rem 0.6rem;
        margin-bottom: 1rem; } }
    .menu-list-container .menu-level-1 a {
      display: block;
      text-decoration: none;
      color: #001429; }
      .menu-list-container .menu-level-1 a span {
        display: inline-block; }
      .menu-list-container .menu-level-1 a.active, .menu-list-container .menu-level-1 a:hover {
        color: #ef8122;
        transition: ease 0.3s; }
    .menu-list-container .menu-level-1 .menu-icon {
      margin-left: 6px;
      display: none; }
      .menu-list-container .menu-level-1 .menu-icon.show {
        display: inline-block; }
  .menu-list-container .menu-level-2 {
    display: none;
    text-align: right;
    min-width: 10rem;
    padding: 0;
    margin: 0;
    list-style: none;
    border-radius: 0.375rem;
    margin-top: 0.5rem; }
    .menu-list-container .menu-level-2 li {
      padding: .5rem 0;
      margin-right: 2rem; }
      .menu-list-container .menu-level-2 li a {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between; }
        .menu-list-container .menu-level-2 li a span {
          display: block;
          -webkit-flex: 1;
              -ms-flex: 1;
                  flex: 1; }
        .menu-list-container .menu-level-2 li a .menu-icon {
          padding-left: 1rem; }

/* #Banner Section */
#Banner {
  position: relative; }
  #Banner .animate__fadeIn {
    --animate-duration: 2s; }
  #Banner .animate__animated.animate__bounce {
    --animate-duration: 2s; }
  #Banner .btn-scroll-down {
    position: absolute;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-decoration: none;
    bottom: 2rem;
    left: 50%;
    margin: auto;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-animation-name: move;
            animation-name: move;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease; }
    #Banner .btn-scroll-down img {
      width: 20px;
      height: auto; }
    #Banner .btn-scroll-down i {
      color: #fff;
      font-size: 1.2rem;
      opacity: 0;
      -webkit-animation-name: visible;
              animation-name: visible;
      -webkit-animation-duration: 1.5s;
              animation-duration: 1.5s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease;
              animation-timing-function: ease; }
      #Banner .btn-scroll-down i:last-child {
        margin-top: -10px;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s; }

@-webkit-keyframes move {
  0% {
    bottom: 2rem; }
  50% {
    bottom: 1rem; }
  100% {
    bottom: 2rem; } }

@keyframes move {
  0% {
    bottom: 2rem; }
  50% {
    bottom: 1rem; }
  100% {
    bottom: 2rem; } }

@-webkit-keyframes visible {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes visible {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.banner-container .slick-arrow {
  opacity: 0; }
  @media (max-width: 767px) {
    .banner-container .slick-arrow {
      opacity: 1; } }

.banner-container:hover .slick-arrow {
  opacity: 1; }

.banner-items {
  position: relative;
  height: 100vh; }
  @media (max-width: 767px) {
    .banner-items {
      height: 70vh; } }
  .banner-items__images {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%; }
    .banner-items__images::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.25); }
  .banner-items__text-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 15%;
    padding-left: 5rem;
    padding-right: 5rem;
    max-width: 58rem; }
    @media (max-width: 767px) {
      .banner-items__text-container {
        padding-left: 1rem;
        padding-right: 1rem;
        max-width: 100%;
        -webkit-align-items: flex-end;
            -ms-flex-align: end;
                align-items: flex-end;
        margin-top: 50%;
        margin-bottom: 2rem; } }
  .banner-items__title {
    font-family: 'Gogh-BoldItalic';
    display: block;
    color: #fff;
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: .5rem;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .banner-items__title {
        font-size: 2rem;
        line-height: 2rem;
        margin-bottom: 2rem; } }
  .banner-items__desc {
    font-family: 'Poppins-Regular';
    color: #fff;
    font-size: 1.6rem; }
    @media (max-width: 767px) {
      .banner-items__desc {
        font-size: 1.2rem; } }
  .banner-items__project-name {
    color: #fff;
    position: absolute;
    bottom: 4rem;
    right: 8rem;
    font-family: 'Poppins-Regular'; }
    @media (max-width: 767px) {
      .banner-items__project-name {
        right: 1rem;
        left: 1rem;
        bottom: 2rem;
        font-size: 14px; } }

/* #About Section */
.about-desc {
  font-size: 18px;
  text-align: justify;
  font-weight: 600;
  font-family: 'Poppins-Regular'; }
  .about-desc .highlight {
    color: #f57e20;
    font-family: 'Poppins-Medium';
    font-style: italic;
    font-weight: bold; }

.about-image-container {
  margin-right: 2rem; }

.about-image {
  width: 479px;
  height: auto; }
  @media (max-width: 767px) {
    .about-image {
      width: 200px;
      margin: auto;
      margin-bottom: 1rem; } }

/* #OurProduct Section */
#Product {
  margin-top: 10rem;
  margin-bottom: 10rem; }
  #Product .product-images {
    transition: ease-in .2s; }
    @media (max-width: 767px) {
      #Product .product-images {
        -webkit-filter: none;
                filter: none; } }
  #Product .product-main {
    width: 92%;
    margin: auto; }
    @media (max-width: 767px) {
      #Product .product-main {
        width: 200px;
        margin-bottom: 1rem; } }

#Project * {
  min-height: 0;
  min-width: 0; }

#Project .project-slider__container {
  position: relative;
  height: 90vh;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding-bottom: 4rem; }
  @media (max-width: 767px) {
    #Project .project-slider__container {
      height: 500px; } }
  #Project .project-slider__container::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.4;
    background-image: url("../images/bg-our-project.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

#Project .project-images-container {
  border-radius: 68px; }
  #Project .project-images-container.lt1 .images-container-overlay {
    -webkit-transform: translate(10%, 0) scale(0.75);
            transform: translate(10%, 0) scale(0.75); }
  #Project .project-images-container.rt1 .images-container-overlay {
    -webkit-transform: translate(-10%, 0) scale(0.75);
            transform: translate(-10%, 0) scale(0.75); }

#Project .images-container-overlay {
  transition: all 0.4s ease;
  border-radius: 21%;
  margin: 0 8px; }
  @media (max-width: 767px) {
    #Project .images-container-overlay {
      border-radius: 20px; } }
  #Project .images-container-overlay .overlay-text {
    font-size: 1.6rem; }

#Project .slider-project {
  width: 100%; }

#Project .slick-arrow {
  background: #1d283a; }

#Project .slick-center .images-container-overlay {
  transition: all 0.4s ease; }

/* #MediaHighlight Section */
#Media .section-title {
  margin-bottom: 4rem; }

#Media .media-container {
  margin: 0 auto 2rem; }

#Media .media-content__container {
  padding: 2rem;
  border-radius: 2rem;
  background-color: #f4f4f4;
  margin: 0 .5rem; }
  @media (max-width: 767px) {
    #Media .media-content__container {
      display: block; } }

#Media .media-content__text {
  height: 300px;
  overflow: hidden; }

#Media .media-content__title {
  font-family: 'Poppins-Medium';
  font-size: 25px;
  text-align: center;
  margin-top: 2rem;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; }
  @media (max-width: 767px) {
    #Media .media-content__title {
      margin-top: 1rem; } }

#Media .media-content__desc {
  font-family: 'Poppins-Regular';
  margin-top: .8rem;
  text-align: justify;
  font-size: 15px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; }

#Media .media-images {
  width: 100%;
  height: 300px;
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */ }
  #Media .media-images-container {
    border-radius: 26px; }
    @media (max-width: 767px) {
      #Media .media-images-container {
        margin-right: 0; } }

#Media .slick-next {
  right: -45px; }
  @media (max-width: 767px) {
    #Media .slick-next {
      right: 0px; } }

#Media .slick-prev {
  left: -45px; }
  @media (max-width: 767px) {
    #Media .slick-prev {
      left: 0px; } }

.images-container-overlay {
  position: relative;
  overflow: hidden;
  border-radius: 42px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .images-container-overlay:hover .overlay-text {
    opacity: 1; }
  .images-container-overlay .overlay-text {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    font-family: 'Poppins-Regular';
    font-size: 1.4rem;
    padding: .5rem;
    color: #fff;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    opacity: 0;
    transition: all .4s ease;
    background-color: rgba(0, 0, 0, 0.5); }

/* #Footer Section */
#FooterMenu {
  background-color: #9b9b9b;
  padding: 2rem 0; }
  #FooterMenu ul {
    list-style-type: none; }
    #FooterMenu ul li {
      padding: 4px 0;
      border-bottom: 1px dotted #4d4d4d; }
  #FooterMenu a {
    text-decoration: none; }
  #FooterMenu a,
  #FooterMenu p {
    font-family: 'Poppins-Regular';
    color: #fff; }
  #FooterMenu .widget ul {
    padding: 20px 0 40px 0; }
  #FooterMenu .widget p {
    margin-top: 1rem;
    text-align: justify; }
  #FooterMenu .footer-menu-title {
    text-transform: uppercase;
    font-weight: 600; }

.social-media-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: -3rem; }
  @media (max-width: 767px) {
    .social-media-container {
      margin-top: 1rem;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  .social-media-container .separator {
    width: 4px;
    height: 30px;
    background-color: #fff;
    margin: 0 1rem; }
    @media (max-width: 767px) {
      .social-media-container .separator {
        margin: 0 .8rem; } }

.social-media-link {
  display: inline-block;
  font-size: 2rem;
  margin-right: 12px; }
  @media (max-width: 767px) {
    .social-media-link {
      font-size: 1.6rem;
      margin-right: 4px; } }
  .social-media-link:last-child {
    margin-right: 0; }

.social-media-account a {
  font-size: 22px;
  font-family: 'Poppins-Regular';
  font-weight: 600;
  letter-spacing: 1px; }
  @media (max-width: 767px) {
    .social-media-account a {
      font-size: 14px; } }

.copyright {
  background-color: #f8991d;
  padding: 30px 0; }
  .copyright p {
    margin-bottom: 0;
    color: #fff; }

/* Scroll to Top */
.scroll-top {
  opacity: 0;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  cursor: pointer;
  background-color: #f57e20;
  padding: .6rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 2px;
  transition: all .5s ease; }
  .scroll-top.show {
    opacity: 1; }
